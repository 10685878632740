import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const ronakExpertisesData = allData.vimalExpertisesData;

const userData = {
    name: "Rajkumar. M. Jain",
    designation: "Assistant Project Manager",
    image: '../assets/img/ronak-pipaliya/rajkumar-jain.png',
    tag: `raj-jain`,
    phones: ['(+91) 9099502247'],
    // emails: ['vimal@vasundhara.io', 'vimal.vasundhara@gmail.com'],
    emails: ['raj@vasundhara.io'],
    linkedin: 'https://www.linkedin.com/in/rajkumar-m-jain-3517a8212',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+919099502247`,
    description: [
        `He is an Assistant Project Manager at Vasundhara Infotech, renowned for driving company growth through exceptional leadership skills.`,
        `His expertise in technology, mobile app development, e-commerce, and navigating the digital revolution positions him as a valuable asset in any organization. `,
        `His dedication to innovation and fostering a collaborative environment sets him apart as a forward-thinking project manager.`,
        `He ensures proactive communication of project progress to team members, fostering a culture of continuous learning and development.`,
        `He exhibits exemplary communication skills with clients and team members at every project stage, resulting in on-time project completion.`,
        `Dedication to maintaining continuous engagement with team members alleviates anxiety stemming from uncertainty.`,
        `Adapts seamlessly to changing strategic objectives, promoting transparency and effectiveness in communication.`,
        `He maintains vigilant oversight of the web development process, actively troubleshooting issues during development and testing.`,
        `He demonstrates an adept ability to lead multi-functional teams, consistently achieving customer objectives.`,
    ]
}

function RajkumarJain() {

    const tabNameData = ['App Development', 'Website Development'];
    useEffect(() => {
        allApiData.aosinit();
    }, []);

    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=website_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        //portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={ronakExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default RajkumarJain